/* Track */
.ew-video-bg ul::-webkit-scrollbar{
    width: 4px; /* Width of the scrollbar */
}

.ew-video-bg ul::-webkit-scrollbar-track {
    background: #E8E8EA; /* Color of the track */
}

/* Handle */ 
.ew-video-bg ul::-webkit-scrollbar-thumb {
    background: #178EFC; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the scrollbar handle */
}

/* Handle on hover */
.ew-video-bg ul::-webkit-scrollbar-thumb:hover {
    background: #178EFC; /* Color of the scrollbar handle on hover */
}

.modal {
    display: flex;
    max-height: 100vh;
    max-height: calc(100vh - 4rem);
    min-height: -webkit-fill-available;
    height: 100%;
    inset: 0;
    position: fixed;
    overflow-y: scroll;
    padding-bottom: 2rem;
    padding-top: 2rem;
    width: 100vw;
    z-index: 999;
    .modal-background {
        background-color: #44444469;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        backdrop-filter: blur(5px);
        width: 100%;
        opacity: 1;
        visibility: inherit;
        height: 100%;
        inset: 0;
        position: fixed;
        transition-property: none;
    }
    .modal-container {
        opacity: 1;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
        display: flex;
        flex-direction: column;
        margin: auto;
        position: relative;
        transition-property: none;
        width: 100%;
        z-index: 0;
        padding: 2.5rem 2.5rem 2rem;
        max-width: 924px;
        &.with-header {
            padding: 0;
            .modal-header {
                border-bottom: 1px solid #E8EAF0;
                position: relative;
                padding: 1.5rem 2.5rem 0.8rem 1.9rem;
                h3 {
                    font-size: 20px;
                    font-weight: 500;
                    margin: 0;
                }
                .close-btn {
                    color: #2B2B30;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    font-size: 24px;
                    cursor: pointer;
                }
            }
        }
    }
    &.setting-popup {
        .outer-setting-con {
            flex-direction: column;
            text-align: center;
            width: 100%;
            display: flex;
            h3 {
                font-size: 18px;
                font-weight: 500;
                color: #fff;
                position: relative;
            }
        }
    }

    &.session-settings {
        text-align: center;
        display: none;
        &.active {
            display: block; 
            .modal-container {
                opacity: 1; /* When active class is added, set opacity to 1 */
                width: 960px;
                padding: 0;
                // overflow: hidden;
                .close-btn {
                    color: #64748B;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    font-size: 24px;
                    cursor: pointer;
                }
            }
            .modal-background {
            opacity: 1;
            }
        }
    }
    &.invite-guests {
        display: none;
        &.active {
            display: block; 
        }
        .modal-container {
            width: 600px;   
        }
    }
    &.launch-event {
        display: none;
        color: #000;
        &.active {
            display: block;
        }
        .modal-container {
            width: 480px;   
        }
        .launch-event-info {
            flex-direction: column;
            padding: 0 35px;
            margin-bottom: 15px;
            ul {
                margin-top: 10px;
                flex-direction: column;
                li {
                    margin: 10px 0 10px 15px;
                    font-size: 14px;
                    color: #666;
                    font-weight: 400;
                    line-height: 24px;
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        left: -20px;
                        top: 5px;
                        width: 12px;
                        height: 12px;
                        border-radius: 10px;
                        background: #DFEBFF;
                    }
                }
            }
            span.co-host-info {
                border-radius: 16px;
                background: #DFEBFF;
                padding: 25px 20px;
                font-size: 14px;
                font-weight: 500;
                color: #333;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    margin-right: 10px;
                }
            }
            button {
                margin: 30px 0;
            }
        }
    }
    &.create-polls {
        display: none;
        &.active {
            display: block; 
        }
        .modal-container {
            width: 530px;
            .create-poll-info {
                flex-direction: column;
                padding: 24px;
                .ew-form-field {
                    label {
                        color: #666666;
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
                .polls-options {
                    h3 {
                        justify-content: space-between;
                        color: #666666;
                        font-weight: 400;
                        font-size: 16px;
                    }
                    .create-option {
                        margin-bottom: 15px;
                        position: relative;
                        input {
                            width: 100%;
                        }
                        span.remove {
                            position: absolute;
                            right: 15px;
                            top: 15px;
                        }
                        &.remove {
                            input {
                                padding: 0 55px 0 20px;
                            }
                        }
                    }
                }
            }
            .modal-footer {
                padding: 0 24px 24px;
                justify-content: space-between;
            }
        }
    }
    &.polls-offers-preview {
        .modal-background {
            filter: blur(0);
            -webkit-filter: blur(0);
            backdrop-filter: blur(0);
        }
        &.react-draggable {
            overflow: hidden;
        }
        &.polls-preview-popup {
            .modal-container {
                width: 360px;
            }
        }
        &.offers-preview-popup {
            .modal-container {
                width: 340px;
                .offers-inner-container {
                    height: 100%;
                    padding: 0;
                    width: 100%;
                }
            }
        }
        .modal-container {
            .modal-header {
                padding: 1rem 1.3rem 0.8rem;
                background: #E2E8FC;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                h3 {
                    align-items: center;
                    img {
                        margin-right: 10px;
                    }
                }
                .close-btn {
                    top: 13px;
                }
            }
            .polls-content-area {
                .polls-inner-container {
                    height: auto;
                }
                .polls-info-main {
                    margin: 0;
                    box-shadow: none;
                    border: none;
                }
            }
        }
    }
    &.confirmation-popup {
        display: none;
        &.active {
            display: block; 
        }
        .modal-container {
            width: 540px;
            .confirmation-info {
                padding: 24px;
                p {
                    color: #666666;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 32px;
                    margin: auto;
                }
            }
        }
        .modal-footer {
            border-top: 1px solid #E8EAF0;
            padding: 19px 24px;
            > div {
                gap: 15px;
                justify-content: end;
                width: 100%;            
            }
        }
    }
    &.easycast-popup {
        display: none;
        &.active {
            display: block; 
        }
        .modal-container {
            width: 470px;
            .easycast-info {
                padding: 24px;
                flex-direction: column;
                .ew-form-field {
                    &.only-label {
                        label {
                            margin-bottom: 10px;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }            
        }
        .modal-footer {
            padding: 0 24px 24px;
        }
    }
    &.accessibility-popup {
        display: none;
        &.active {
            display: block; 
            z-index: 10000;
        }
        .modal-container {
            width: 870px;
            padding: 2rem 2.5rem 2rem;
            .modal-content {
                flex-direction: column;
                .accessibility-heading {
                    text-align: center;
                    span {
                        position: absolute;
                        right: 20px;
                        top: 25px;
                        cursor: pointer;
                        font-size: 28px;
                    }
                    h3 {
                        margin: 0;
                        font-weight: 500;
                    }
                }
                .accessibility-info {
                    gap: 40px;
                    margin-top: 30px; 
                    .img-content {
                        width: 295px;
                        max-width: 100%;
                    }
                    .accessibility-content {
                        ul {
                            li {
                                display: flex;
                                gap: 15px;
                                margin-bottom: 20px;
                                h4 {
                                    margin: 5px 0 0;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #333333;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;
                                    span {
                                        font-weight: 400;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .access-action {
                            gap: 15px;
                            justify-content: end;
                            margin-top: 35px;
                        }
                    }
                }
            }
        }
    }
    &.sharing-denied-popup {
        display: none;
        &.active {
            display: block; 
        }
        .modal-container {
            width: 550px;
            .modal-content {
                flex-direction: column;
                .screen-sharing-info {
                    padding: 24px;
                    h3 {
                        margin: 0;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    p {
                        color: #666666;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 1.4;
                        margin-top: 10px; 
                    }
                }
            }
        }
        .modal-footer {
            border-top: 1px solid #E8EAF0;
            padding: 19px 24px;
            > div {
                gap: 15px;
                justify-content: end;
                width: 100%;
            }
        }
    }
    &.file-share-popup {
        display: none;
        &.active {
            display: block; 
        }
        .modal-container {
            width: 950px;
            max-width: 950px;
            height: auto;
            max-height: calc(100vh - 50px);
            .modal-header {
                justify-content: space-between;
                .close-btn {
                    position: relative;
                    right: 0;
                    top: 0;
                }
                .tabs.sharing-tabs {
                    gap: 15px;
                    button {
                        border: none;
                        height: 25px;
                        min-width: auto;
                        width: auto;
                        padding: 0;
                        border-radius: 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: #2B2B30;
                        &.active {
                            border-bottom: 3px solid #178EFC;
                        }
                    }
                }
            }
            .modal-content {
                overflow-y: auto;
                // library
                height: 100%;
                overflow-y: auto;
                .tab-content {
                    width: 100%;
                }
                .library-tab {
                    width: 100%;
                    height: 100%;
                    .library-search {
                        padding: 0 35px;
                        margin-bottom: 35px;
                        input[type="search"] {
                            width: 100%;
                            border-radius: 40px;
                            background: #F5F5F5;
                            border: 1px solid #D6D6D6;
                            &.search-input {
                                &:before {
                                    content: "search";
                                    // background: url("../../images/input-search-icon.png");
                                    font-family: 'Material Icons';
                                    color: #888;
                                    font-size: 24px;
                                    pointer-events: none;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                    .library-listing {
                        padding: 0 35px;
                        margin-bottom: 35px;
                        position: relative;
                        ul {
                            flex-wrap: wrap;
                            gap: 10px;
                            li {
                                padding: 12px;
                                flex-direction: column;
                                gap: 10px;
                                width: 165px;
                                border-radius: 4px;
                                background: #F9F9F9;
                                border: 1px solid #F9F9F9;
                                cursor: pointer;
                                position: relative;
                                img.upload {
                                    position: absolute;
                                    right: 5px;
                                    top: 5px;
                                    width: 30px;
                                }
                                .checked {
                                    display: none;
                                    position: absolute;
                                    top: 10px;
                                    left: 10px;
                                }
                                span.img {
                                    height: 96px;
                                }
                                span.file-name {
                                    font-size: 13px;
                                    font-weight: 400;
                                    color: #666666;
                                    max-width: 138px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                &.active, &:hover {
                                    border: 1px solid #178EFC;
                                }
                                &.active {
                                    .checked {
                                        display: block;
                                    }
                                }
                            }
                            .loader-bg {
                                position: relative;
                                .section-loader {
                                    span {
                                        color: #2B2B30;
                                    }
                                }
                            }
                        }
                    }
                    .library-pagination {
                        margin: 20px 0 40px;
                        display: inline-block;
                        width: 100%;
                        ul {
                            gap: 10px;
                            li {
                                box-shadow: 0px 1px 5px 0px #0000001F;
                                box-shadow: 0px 2px 2px 0px #00000024;
                                box-shadow: 0px 1px 1px 0px #00000033;
                                width: 34px;
                                height: 34px;
                                color: #2B2B30;
                                border-radius: 4px;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                a, span {
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #2B2B30;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                &.active {
                                    pointer-events: none;
                                }
                                &:hover, &.active {
                                    background: #178EFC;
                                    a, span {
                                        color: #fff;
                                        img {
                                            filter: invert(1) brightness(2);
                                        }
                                    }
                                }
                                &.previous {
                                    a, span {
                                        img {
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                                &.disabled {
                                    pointer-events: none;
                                    opacity: 0.6;
                                    &:hover {
                                        background: #fff;
                                        a, span {
                                            img {
                                                filter: invert(0) brightness(0);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


                .file-share-info {
                    width: 100%;
                    padding: 20px 25px 25px;
                    .file-upload {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        padding: 60px 20px;
                        border: 2px dashed #7782A3;
                        border-radius: 8px;
                        img {
                            width: 56px;
                            height: 55px;
                        }
                        label {
                            font-weight: 700;
                            font-size: 14px;
                            color: #090039;
                            flex-direction: column;
                            align-items: center;
                            gap: 5px;
                            span {
                                font-size: 14px;
                                font-weight: 400;
                                color: #7782A3;
                            }
                        }
                        p.selected-file {
                            margin: 10px 0 0 0;
                            font-size: 14px;
                            display: flex;
                            gap: 5px;

                            span {
                                font-weight: 500;
                            }
                        }
                        button.remove-file-btn {
                            position: absolute;
                            width: 24px;
                            height: 24px;
                            min-width: 24px;
                            padding: 0;
                            font-size: 20px;
                            right: 38px;
                            margin-top: 20px;
                            border: 1px solid #e44258;
                        }
                    }
                    span.select-file {
                        position: relative;
                        overflow: hidden;
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        input {
                            border: none;
                            padding: 0;
                            border-radius: 0;
                            position: absolute;
                            left: 0;
                            top: 0;
                            opacity: 0;
                            width: 130px;
                            min-height: 37px;
                            cursor: pointer;
                            &:focus {
                                border: none;
                                outline: none;
                            }
                        }
                        button {
                            height: 40px;
                            line-height: 40px;
                            padding: 0;
                            font-size: 14px;
                            font-weight: 500;
                            margin-left: 8px;
                        }
                        label {
                            display: inline-block;
                            background-color: #007bff;
                            color: white;
                            padding: 10px 20px;
                            border-radius: 8px;
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: 500;
                            height: 40px;
                            cursor: pointer;
                        }
                    }
                    .file-progress {
                        text-align: center;
                        p {
                            margin: 12px 0 0;
                            font-size: 14px;
                        }
                        progress {
                            // appearance: none;
                            width: 180px;
                            height: 15px;
                            margin-top: 0px;
                            overflow: hidden;
                        }
                    }
                }
                .file-upload-form {
                    padding: 0 35px;
                    .ew-form-field {
                        label {
                            justify-content: flex-start;
                            gap: 5px;
                            margin-bottom: 10px;
                            color: #333333;
                            font-weight: 400;
                            span {
                                color: #2B2B30;
                            }
                        }
                        label.checkbox-label {
                            input[type="checkbox"] {
                                width: 20px;
                                height: 20px;
                                min-height: auto;
                            }
                        }
                    }
                }
            }
            .modal-footer {
                border-top: 1px solid #E8EAF0;
                padding: 19px 24px;
                > div {
                    justify-content: flex-end;
                    width: 100%;
                    gap: 15px;
                }
            }
        }
    }

    &.delete-confirmation {
        position: absolute;
        width: 100%;
        display: flex;
        padding: 0 4px;
        .modal-background {
            position: absolute;
            background-color: rgba(68, 68, 68, 0.20);
            -webkit-backdrop-filter: none;
            backdrop-filter: none;
        }
        .modal-container {
            width: 270px;
            &.with-header {
                .modal-header {
                    padding: 1.2rem 0.5rem 0.8rem 0.9rem;
                    h3 {
                        font-size: 14px;
                    }
                }
                .confirmation-info {
                    padding: 14px;
                    p {
                        line-height: 24px;
                        font-size: 14px;
                    }
                }
                .modal-footer {
                    padding: 15px 24px;
                }
            }
        }
    }
}


.live-room-settings-box, .ew-bg-selection {
    display: flex;
    gap: 70px;
    .camera-settings {
        flex: 1;
        max-width: 350px;
        text-align: left;
        h4 {
            margin: 0 0 10px;
            font-weight: 500;
        }
        .video-container {
            position: relative;
            width: 100%;
            height: auto;
            border: 1px solid #ccc;
            border-radius: 12px;
            overflow: hidden;
            background: #1C1B1E;
            min-width: 320px;
            > div {
                width: 100%;
            }
            video {
                width: 100%;
                height: 260px;
                object-fit: cover;
            }
            .no-video-box {
                height: 260px;
            }
            .controls {
                position: absolute;
                bottom: 10px;
                right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                .control-button {
                    background: #00000080;
                    border: none;
                    color: white;
                    cursor: pointer;
                    margin: 0 5px;
                    border-radius: 10px;
                    width: 38px;
                    height: 38px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: auto;
                    padding: 6px;
                    &.video-on, &.mic-on {
                        svg {
                            &.on {
                                display: block;
                            }
                            &.off {
                                display: none;
                            }
                        }
                    }
                    &.video-off, &.mic-off {
                        background: #178EFC;
                        svg {
                            &.on {
                                display: none;
                            }
                            &.off {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .ew-video-bg {
        margin-top: 20px;
        h4 {
            font-size: 14px;
            color: #999999;
            margin: 0 0 10px;
            font-weight: 400;
        }
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 10px 30px;
            max-height: 180px;
            overflow-y: auto;
            li {
                width: 88px;
                height: 51px;
                border-radius: 4px;
                background: #F4F6F8;
                border: 1px solid #D9D9D9;
                list-style: none;
                cursor: pointer;
                &.bg-default {
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        height: 100%;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        color: #C5C5C5;
                        gap: 4px;
                        svg {
                            min-height: 19px;
                            path {
                                fill: #C5C5C5;
                            }
                        }
                    }
                    &:hover {
                        span {
                            color: #178EFC;
                            svg {
                                path {
                                    fill: #178EFC;
                                }
                            }
                        }
                    }
                    &.active {
                        border: 1px solid #178EFC;
                        span {
                            color: #178EFC;
                            svg {
                                path {
                                    fill: #178EFC;
                                }
                            }
                        }
                    }
                }
                &.active {
                    border: 1px solid #178EFC;
                }
            }
        }
    }

    .ew-other-video-settings {
        flex: 1;
        min-width: 0;
    }
}

.ew-form-field {
    text-align: left;
    flex-direction: column;
    display: flex;
    margin-bottom: 20px;
    &.marginb-0 {
        margin-bottom: 0;
    }
    &.mic-actions {
        margin-top: 10px;
    }
    label {
        color: #2B2B30;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            font-size: 12px;
            color: #E44258;
            &.error-msg {
                img {
                    position: relative;
                    top: 4px;
                    margin-left: 3px;
                }
            }
        }
    }
    #dropdown {
        input {
            min-height: auto;
        }
    }
    span.label-sub-heading {
        font-size: 14px;
        color: #999999;
        font-weight: 400;
        margin: 10px 0;
        display: flex;
        align-items: center;
        span.icon {
            width: 20px;
            position: relative;
            top: 2px;
            margin-right: 3px;
        }
        &.test-output {
            .test-audio {
                cursor: pointer;
                border: 1px solid #E8EAF0;
                color: #666666;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                margin-left: 10px;
                padding: 2px 10px 2px 5px;
                img {
                    margin-right: 3px;
                }
            }
        }
    }
    .mic-volume {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 2px;
        input[type="range"] {
            min-height: auto;
            // -webkit-appearance: none;
            height: 4px;
            padding: 0;
            // background: #50f007;
            border: none;
            // background: linear-gradient(to right, #4CAF50 50%, #ddd 50%); /* Initial background */
            border-radius: 5px; /* Rounded corners */
            outline: none; /* Remove default outline */
            // transition: background 0.3s ease;
            // &::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb {
            //     background: #002e74;
            //     -webkit-appearance: none;
            //     appearance: none;
            // }
            // &::-moz-range-track, &::-ms-track {
            //     background: #000;
            //     border-color: #000;
            //     color: #178EFC;
            // }
        }
    }
    span.microphone-volume {
        height: 4px;
        width: 100%;
        background: #DFEBFF;
        border-radius: 50px;
        position: relative;
        overflow: hidden;
        span {
            background: #178EFC;
            height: 4px;
            position: absolute;
            border-radius: 50px;
            transition: all .05s;
        }
    }
    .css-b62m3t-container {
        .css-13cymwt-control, .css-t3ipsp-control {
            min-height: 46px;
            border-radius: 8px;
            border-width: 1px;
            .css-1fdsijx-ValueContainer {
                padding: 2px 18px;
                .css-1dimb5e-singleValue {
                    color: #2B2B30;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
        .css-1hb7zxy-IndicatorsContainer {
            .css-1u9des2-indicatorSeparator {
                display: none;
            }
        }
    }
    .output-device {
        display: flex;
        align-items: center;
        width: 100%;
        input {
            height: 4px;
            border-radius: 5px;
            border: none;
            background-color: #DFEBFF; /* Set the color of the range thumb */
            box-shadow: none; /* Remove any box shadow */
            min-height: 4px;
            padding: 0;
            &::-webkit-slider-thumb {
                -webkit-appearance: none; /* Remove default styling */
                width: 100%; /* Set the width of the range thumb */
                height: 4px; /* Set the height of the range thumb */
                border-radius: 50%; /* Set the border radius to make it round */
                cursor: pointer;
                background: #178EFC;
                border: 0px solid #178EFC !important;
                outline: none !important;
            }
            &:focus {
                outline: none;
            }
        }
    }
    &.toggle-field {
        .label-sub-heading {
            justify-content: space-between;
            display: flex;
        }
    }
}

.ew-form-field-action {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    button {
        height: 48px;
        border: 1px solid #666666;
        min-width: 100px;
        border-radius: 8px;
        padding: 12px 24px;
        font-size: 16px;
        font-weight: 500;
        color: #666;
        font-family: "Roboto", sans-serif;
        background: #fff;
        cursor: pointer;
        &.primary-button {
            background: #178EFC;
            border: 1px solid #178EFC;
            color: #fff;
        }
    }
}

// session setting box
.settings-left-sidebar {
    width: 315px;
    border-right: 1px solid #E2E8F0;
    text-align: left;
    padding: 30px;
    .tabs {
        flex-direction: column;
        h3 {
            color: #070029;
            font-size: 18px;
            margin: 0;
        }
        h4 {
            font-size: 16px;
            color: #178EFC;
            font-weight: 500;
            margin: 16px 0;
        }
        button {
            font-size: 15px;
            color: #090039;
            background: none;
            border: none;
            outline: none;
            text-align: left;
            cursor: pointer;
            padding: 15px 20px;
            height: 50px;
            border-radius: 4px;
            transition: all .4s ease-in-out;
            &.active, &:hover {
                background: #E8EAF0;
            }
            span {
                width: 35px;
                text-align: center;
                min-height: 24px;
                &.tab-name {
                    width: auto;
                }
            }
        }
    }
}

.settings-right-area {
    background: #F8FAFC;
    flex: 1;
    padding-top: 40px;
    border-radius: 8px;
    .ew-setting-area-section {
        padding: 0 30px 30px;
    }
}


.camera-check-box {
    background: #161923;
    border-radius: 8px;
    width: 350px;
    height: 224px;
    flex-direction: column;
    position: relative;
    margin: 0 auto 30px;
    overflow: hidden;
    max-width: 100%;
    .camera-view {
        width: 100%;
        height: 100%;
        video {
            width: 100%;
            height: 100%;
        }
    }
    .username {
        background: #A2CFFA;
        border-radius: 50%;
        height: 100px;
        width: 100px;
        color: #fff;
        font-size: 40px;
        position: relative;
        top: -35px;
    }
    .controls {
        border-radius: 12px;
        overflow: hidden;
        position: absolute;
        bottom: 24px;
        gap: 1px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        button {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
            border: none;
            color: #fff;
            height: 54px;
            flex-direction: column;
            padding: 4px 20px 6px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            span {
                height: 28px;
                min-height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.video-off, &.mic-off {
                svg.on {
                    display: block;
                }
                svg.off {
                    display: none;
                }
            }
            &.video-on,  &.mic-on {
                svg.on {
                    display: none;
                }
                svg.off {
                    display: block;
                }
            }
            &:hover {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
            }
            
            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

.ew-form-field.ew-bg-selection {
    gap: 0;
}

.select-video-layout {
    gap: 15px;
    .layout-view {
        .video-layout {
            gap: 30px;
            margin-top: 10px;
            cursor: pointer;
            .video-frame {
                border: 1px solid #E8E8EA;
                padding: 8px;
                border-radius: 4px;
                width: 156px;
                height: 116px;
                img {
                    width: 100%;
                }
            }
            &.active, &:hover {
                .video-frame {
                    border-color: #178EFC;
                }
            }
            span {
                font-size: 14px;
                color: #999999;
                text-align: center;
                max-width: 159px;
                word-wrap: break-word;
                display: inline-block;
                width: 100%;
                text-align: center;
                margin-top: 5px;
            }
        }
    }
}

// invite guests
.invite-guests {
    .modal-content {
        flex-direction: column;
        padding: 1.5rem 2.5rem 0.8rem;
        .tabs.invite-tabs {
            gap: 10px;
            margin-bottom: 10px;
            button {
                border-radius: 8px;
                padding: 13px 12px;
                border: none;
                font-size: 16px;
                font-weight: 500;
                background: #fff;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &.active, &:hover {
                    background: #DFEBFF;
                }
            }
        }
        .ew-invite-content {
            .copy-link {
                button {
                    margin-top: 10px;
                    height: 55px;
                    color: #2B2B30;
                }
            }
            .social-share {
                margin-top: 20px;
                ul {
                    gap: 20px;
                    li {
                        width: 60px;
                        height: 60px;
                        background: #fff;
                        border-radius: 8px;
                        box-shadow: 0px 4px 24px 0px #2B2B3029;
                        a {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .modal-footer {
        padding: 0.5rem 2rem 1.5rem;
        display: flex;
        justify-content: flex-end;
        // button {

        // }
    }
}

.dummy-live-img {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}

.dummmy-live-bg {
    background: url('../../images/greenroom-skeleton.png') no-repeat;
    position: fixed;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.ew-loader-con {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #04040499;
    z-index: 9999;
    &.fullscreen {
        position: fixed;
    }
    .dummmy-loader-bg {
        background: url('../../images/greenroom-skeleton.png') no-repeat;
        position: fixed;
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    .ew-blur {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(68, 68, 68, 0.4117647059);
        filter: blur(5px);
        -webkit-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(3px);
    }
}
.loader-inner {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    flex-direction: column;
    .square-container {
        position: relative;
        width: 64px;
        height: 64px;
        // background-color: #1A1A1A;
        margin-bottom: 0;
        img {
            max-width: 100%;
            height: 100%;
        }
        .moving-element {
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: #22B060;
            border-radius: 0;
            animation: moveAroundSquare 2s linear infinite;
        }
    }
    span {
        font-size: 18px;
        font-weight: 500;
        color: #F9F9F9;
        text-align: center;
    }
}

.loading_box {
    position: relative;
    padding: 15px;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border-radius: 5px;
    background: #1cb45e;
    margin-top: 0;
    margin-bottom: 20px;
    .ewp_bar {
        position: absolute;
        top: 7px;
        background-color: transparent;
        background: url('../../images/ewb_icon_bar_white.png') no-repeat;
        width: 22px;
        height: 47px;
        display: inline-block;
        animation-name: bounce_fountainG;
        -o-animation-name: bounce_fountainG;
        -ms-animation-name: bounce_fountainG;
        -webkit-animation-name: bounce_fountainG;
        -moz-animation-name: bounce_fountainG;
        animation-duration: 1.5s;
        -o-animation-duration: 1.5s;
        -ms-animation-duration: 1.5s;
        -webkit-animation-duration: 1.5s;
        -moz-animation-duration: 1.5s;
        animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-direction: normal;
        -o-animation-direction: normal;
        -ms-animation-direction: normal;
        -webkit-animation-direction: normal;
        -moz-animation-direction: normal;
        transform: scale(0.7);
        -o-transform: scale(0.7);
        -ms-transform: scale(0.7);
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
    }
    .ewp_bar.bar1 {
        left: 5px;
        animation-delay: 0.6s;
        -o-animation-delay: 0.6s;
        -ms-animation-delay: 0.6s;
        -webkit-animation-delay: 0.6s;
        -moz-animation-delay: 0.6s;
    }
    .ewp_bar.bar2 {
        left: 20px;
        animation-delay: 0.75s;
        -o-animation-delay: 0.75s;
        -ms-animation-delay: 0.75s;
        -webkit-animation-delay: 0.75s;
        -moz-animation-delay: 0.75s;
    }
    .ewp_bar.bar3 {
        left: 35px;
        animation-delay: 0.9s;
        -o-animation-delay: 0.9s;
        -ms-animation-delay: 0.9s;
        -webkit-animation-delay: 0.9s;
        -moz-animation-delay: 0.9s;
    }
}

@keyframes bounce_fountainG {
    0% {
      transform: scale(0.7);
    }
  
    100% {
      transform: scale(0.7);
      opacity: 0.2;
    }
  }
  
  @-o-keyframes bounce_fountainG {
    0% {
      -o-transform: scale(0.7);
    }
  
    100% {
      -o-transform: scale(0.7);
      opacity: 0.2;
    }
  }
  
  @-ms-keyframes bounce_fountainG {
    0% {
      -ms-transform: scale(0.7);
    }
  
    100% {
      -ms-transform: scale(0.7);
      opacity: 0.2;
    }
  }
  
  @-webkit-keyframes bounce_fountainG {
    0% {
      -webkit-transform: scale(0.7);
    }
  
    100% {
      -webkit-transform: scale(0.7);
      opacity: 0.2;
    }
  }
  
  @-moz-keyframes bounce_fountainG {
    0% {
      -moz-transform: scale(0.7);
    }
  
    100% {
      -moz-transform: scale(0.7);
      opacity: 0.2;
    }
  }


@keyframes moveAroundSquare {
    0% {
        top: 0;
        left: 0;
    }
    25% {
        top: 0;
        left: calc(100% - 16px);
    }
    50% {
        top: calc(100% - 16px);
        left: calc(100% - 16px);
    }
    75% {
        top: calc(100% - 16px);
        left: 0;
    }
    100% {
        top: 0;
        left: 0;
    }
}

