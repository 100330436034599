
.thankyou-page {
    height: 100%;
    &:after {
        content: "";
        position: fixed;
        right: 0;
        top: 0; 
        height: 275px;
        width: 351px;
        background: url(../../images/vector-bg-1.png) no-repeat;
    }
    &:before {
        content: "";
        position: fixed;
        left: 0;
        bottom: 0;
        height: 266px;
        width: 368px;
        background: url(../../images/vector-bg-2.png) no-repeat;
    }
    .container {
        margin: 0 auto;
        width: 930px;
        max-width: 100%;
        text-align: center;
        padding: 20px;
        flex-direction: column;
        position: relative;
        z-index: 1;
        height: 100%;
        .ew-logo {
            text-align: center;
            margin-bottom: 70px;
        }
        .thankyou-info {
            gap: 50px;
            @media only screen and (max-width: 980px) {
                flex-direction: column;
            }
            .thankyou-content {
                text-align: left;
                @media only screen and (max-width: 980px) {
                    text-align: center;
                    padding: 0 30px;
                }
                h2 {
                    font-size: 34px;
                    color: #178EFC;
                    margin-bottom: 0;
                }
                p {
                    color: #707070;
                    font-size: 18px;
                    line-height: 32px;
                    margin-top: 8px;
                }
            }
        }
    }
}