

.ew-container-layout {
    &.recording-layout {
        background: #000;
        .left-layout {
            width: 100%;
            .ew-video-container {
                margin-top: 0;
                height: 100vh;
                .presenters {
                    .ew-n-presenters {
                        transition: all .5s;
                        .presenter-column {
                            border: 1px solid #f5f5f56b;
                            transition: all .5s;
                            overflow: hidden;
                            .ew-video-wrapper {
                                background: rgb(154,151,158);
                                background: -moz-radial-gradient(circle, rgba(154,151,158,1) 0%, rgba(88,85,94,1) 40%, rgba(27,26,29,1) 100%);
                                background: -webkit-radial-gradient(circle, rgba(154,151,158,1) 0%, rgba(88,85,94,1) 40%, rgba(27,26,29,1) 100%);
                                background: radial-gradient(circle, rgba(154,151,158,1) 0%, rgba(88,85,94,1) 40%, rgba(27,26,29,1) 100%);
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9a979e",endColorstr="#1b1a1d",GradientType=1);
                                .presenter-options-dropdown {
                                    display: none;
                                }
                                .gradient-bg {
                                    background: rgb(154,151,158);
                                    background: -moz-radial-gradient(circle, rgba(154,151,158,1) 0%, rgba(88,85,94,1) 40%, rgba(27,26,29,1) 100%);
                                    background: -webkit-radial-gradient(circle, rgba(154,151,158,1) 0%, rgba(88,85,94,1) 40%, rgba(27,26,29,1) 100%);
                                    background: radial-gradient(circle, rgba(154,151,158,1) 0%, rgba(88,85,94,1) 40%, rgba(27,26,29,1) 100%);
                                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9a979e",endColorstr="#1b1a1d",GradientType=1);
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                    &.screen_shared_fullscreen {
                        justify-content: flex-end;
                        .ew-n-presenters {
                            flex-wrap: wrap;
                            width: 180px;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;
                            flex-direction: column;
                        }
                        .presenter-column {
                            border: 1px solid #f5f5f56b;
                            min-height: 115px;
                            max-height: 115px;
                            &.screen-sharing-user-fullscreen {
                                width: calc(100% - 210px);
                                height: calc(100% - 20px);
                                left: 10px;
                                padding: 0 !important;
                                z-index: 1;
                                position: fixed;
                                top: 10px;
                                max-height: 100%;
                                transition: all .5s;
                                .ew-video-wrapper {
                                    .presenter-options-dropdown {
                                        display: none;
                                    }
                                }
                            }
                        }
                        
                        &.active-presenter-1 {
                            .presenter-column {
                                &.screen-sharing-user-fullscreen {
                                    width: calc(100% - 20px);
                                }
                            }
                            
                            .presenter-column + .presenter-column {
                                position: fixed;
                                bottom: 20px;
                                z-index: 1;
                                top: inherit;
                                right: 20px;
                                width: 180px;
                                height: 180px;
                                min-width: 180px;
                                min-height: 180px;
                                border-radius: 50%;
                                border: 3px solid #000;
                                .ew-video-wrapper {
                                    .ew-live-video {
                                        video {
                                            -webkit-object-fit: cover !important;
                                            -moz-object-fit: cover !important;
                                            -ms-object-fit: cover !important;
                                            object-fit: cover !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    &.with-header {
        .left-layout {
            .layout-header {
                display: flex !important;
            }
            .ew-video-container {
                .presenters.screen_shared_fullscreen {
                    .presenter-column {
                        &.screen-sharing-user-fullscreen {
                            top: 80px;
                            height: calc(100% - 200px);
                        }
                    }
                }
            }
        }
    }
}