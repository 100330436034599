
.toaster-msg-outer-con {
    position: absolute;
    top: 3px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 92;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    gap: 5px;
}

.toaster-mg {
    background: #fff;
    color: #333;
    border-radius: 8px;
    padding: 5px 35px 5px 20px;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 650px;
    max-width: max-content;
    box-shadow: 0 4px 48px 0px rgba(43, 43, 48, 0.0784313725);
        p {
            position: relative;
            margin: 7px 20px 7px 10px;
            font-size: 14px;
            svg {
                margin-right: 10px;
            }
            span {
                font-weight: 500;
                margin-right: 5px;
            }
        }
        a.close {
            top: 1px;
            position: absolute;
            right: 0px;
            padding: 10px 15px;
            opacity: .5;
            &:hover {
                opacity: 1;
            }
        }
    &.permission-denied {
        p {
            &:after {
                content: "";
                position: absolute;
                background: #E6533C;
                left: -49px;
                top: -11px;
                height: calc(100% + 23px);
                width: 5px;            
            }
        }
    }
    &.offer-polls-notification {
        &.for-offer {
            p {
                &:after {
                    content: "";
                    position: absolute;
                    background: #F5B849;
                    left: -54px;
                    top: -11px;
                    height: calc(100% + 23px);
                    width: 5px;            
                }
            }
        }
        &.for-polls {
            p {
                &:after {
                    content: "";
                    position: absolute;
                    background: #23B7E5;
                    left: -54px;
                    top: -11px;
                    height: calc(100% + 23px);
                    width: 5px;            
                }
            }
        }
        p {
            a {
                font-weight: 500;
                color: #178EFC;
                margin: 0 5px;
                text-decoration: none;
            }
        }
    }
    &.screen-sharing-msg {
        padding: 5px 5px 5px 20px;
    }
    &.newtwork-unstable-msg {
        cursor: all-scroll;
        z-index: 99;
        touch-action: none;
    }
    &.newtwork-unstable-msg, &.audio-unmute-msg {
        p {
            &:after {
                content: "";
                position: absolute;
                background: #F5B849;
                left: -55px;
                top: -11px;
                height: calc(100% + 23px);
                width: 5px;            
            }
        }
    }
    &.attendee-alert {
        max-width: 600px;
        padding: 5px 12px;
        align-items: flex-start;
        gap: 10px;
        a.close {
            padding: 0;
        }
        .img-wrapper {
            position: relative;
            top: 0;
        }
        .message-text {
            flex-direction: column;
            p {
                margin: 0;
                padding-right: 20px;
                line-height: 20px;
                &:after {
                    content: "";
                    position: absolute;
                    background: #178EFC;
                    left: -49px;
                    top: -13px;
                    height: calc(100% + 76x);
                    width: 5px;            
                }
            }
            button {
                margin-top: 10px;
            }
        }
    }
}